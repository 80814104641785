// extracted by mini-css-extract-plugin
export var headerContainer = "Header-module--headerContainer--1xq9J";
export var linksContainer = "Header-module--linksContainer--8oyyJ";
export var profileChip = "Header-module--profileChip--2s2cR";
export var profileIcon = "Header-module--profileIcon--3SLvs";
export var profileLabel = "Header-module--profileLabel--2CG02";
export var introContainer = "Header-module--introContainer--21KqB";
export var tagline = "Header-module--tagline--cmp7m";
export var wave = "Header-module--wave--3En8b";
export var waveAnimation = "Header-module--wave-animation--39glO";
export var laptop = "Header-module--laptop--31M6T";
export var laptopAnimation = "Header-module--laptop-animation--fteg6";