// extracted by mini-css-extract-plugin
export var container = "ProjectItem-module--container--1vadJ";
export var containerReverse = "ProjectItem-module--containerReverse--3oyB2";
export var containerSmall = "ProjectItem-module--containerSmall--2OzZh";
export var header = "ProjectItem-module--header--2pwlj";
export var subtitle = "ProjectItem-module--subtitle--3kS4W";
export var years = "ProjectItem-module--years--21D2T";
export var imgContainer = "ProjectItem-module--imgContainer--2pN2p";
export var img = "ProjectItem-module--img--1e7-j";
export var description = "ProjectItem-module--description--2PY7F";
export var descriptionSmall = "ProjectItem-module--descriptionSmall--3RUoF";
export var button = "ProjectItem-module--button--2NwIS";